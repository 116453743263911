export default defineI18nConfig(() => {
  return {
    fallbackLocale: "cs",
    warnHtmlInMessage: "off",
    warnHtmlMessage: "off",
    missing: () => { },
    pluralRules: {
      'cs': (choice, choicesLength) => {
        if (choice === 0) {
          return 0;
        }
        else if (choice === 1) {
          return 1;
        }
        else if (choice <= 4) {
          return 2;
        }
        else {
          return 3;
        }
      },
      'sk': (choice, choicesLength) => {
        if (choice === 0) {
          return 0;
        }
        else if (choice === 1) {
          return 1;
        }
        else if (choice <= 4) {
          return 2;
        }
        else {
          return 3;
        }
      }
    },
  }
});